<template>
  <div>
    <v-dialog v-model="dialog">
      <v-form ref="form">
        <div class="pa-5">
          <h1 class="mb-5">契約譲渡しますか？</h1>
          <div class="d-flex my-5">
            <h3 class="w-25">顧客番号</h3>
            <h3 v-if="enterId">{{ enterId }}</h3>
          </div>
          <div class="d-flex my-5">
            <h3 class="w-25">顧客名</h3>
            <h3>{{ textName }}</h3>
          </div>
          <div class="d-flex justify-end">
            <v-btn class="t-btn--prm mr-4" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
              {{ $t('task_29786.close') }}
            </v-btn>
            <v-btn class="t-btn--red-dark" to @click="actionCreate()">
              譲渡する
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'TransferContract',
  data() {
    return {
      contractId: null,
      toClientId: null,
      // clientId: null,
      // reason: 'Transfer',
      changeData: {},
      file: [],
    };
  },

  props: {
    visible: Boolean,
    currentAttachment: [Object, Array],
    enterId: String,
    textName: String
  },
  computed: {
    ...mapGetters([
      // 'clientSearchPaginatedResponseApplicant',
      // 'getCustomerDetailContractList',
      'getClientBasicInfoTra'
    ]),

    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  mounted() {
    // this.contractAll();
    // this.clientBasicInfoList()
  },

  watch:{
    // enterId(){
    //   this.clientBasicInfoList(parseInt(this.enterId)).then((result) => {
    //     this.textName = result.name
    //   })
    // }
  },  

  methods: {
    ...mapActions(['transferContractMW', 'contractAll', 'clientBasicInfoList']),
    ...mapMutations(['setValidTranferContract', 'setPermissionUser', 'setRoleAdminUser', 'setClientBasicInfoTra']),

    // showName() {
    //   // var textName = '';
    //   // this.getClientBasicInfoTra.find((i) => {
    //   //   if (parseInt(i.clientId) === parseInt(this.enterId)) {
    //   //     textName = i.client.name;
    //   //   }
    //   // });
    //   return textName;
    // },

    async actionCreate() {
      // const ojbClient = this.getClientBasicInfoTra.find(
      //   (i) => i.clientId === parseInt(this.enterId),
      // );
      var reason = 'Transfer';
      const changeData = {
        contractId: parseInt(this.$router.currentRoute.params.id),
        toClientId: parseInt(parseInt(this.enterId)),
        reason: reason,
      };
      this.save();
      await this.transferContractMW(changeData);
      this.$store.commit(
        'setAlertSuccess',
        '譲渡の処理を行いました。',
      );
      this.$emit('done')
    },

    save() {
      this.$emit('close');
    },

    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.w-25 {
  width: 25%;
}
h1 {
  font-size: 28px;
  color: #212121 !important;
  font-weight: bold;
}
h3 {
  font-size: 18px;
  color: #666666 !important;
  font-weight: 400 !important;
}
.v-btn {
  height: 32px !important;
}
// .t-btn--red-dark{
//   width: 72px;
// }

::v-deep {
  .v-dialog {
    width: 380px;
    min-height: 228px;
    background-color: white;
    border-radius: 9px;
  }
}
</style>
