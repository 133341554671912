<template>
  <div>
    <contract-detail>
      <!-- Title -->
      <template v-slot:title>{{ $route.params.type }}契約</template>
      <!-- End Title -->

      <!-- Content  -->
      <template v-slot:default="{isEffective}">
        <div class="mt-12 mx-10 w-10">
          <h6>譲渡先顧客番号</h6>
          <v-form ref="form1">
            <v-text-field
              :rules="id1"
              v-model="enterId"
              :disabled="!checkPerUser || !isEffective"
              @blur="checkId"
              @keydown.enter.prevent="checkId"
            ></v-text-field>
            <v-btn
              class="t-btn--red-dark mt-3"
              @click="changeid"
              :disabled="!checkPerUser || !isAssetClient || !enterId.length"
            >
              譲渡
            </v-btn>
          </v-form>
        </div>

        <div class="mt-8 mx-10 w-10">
          <h6>相続先顧客番号</h6>
          <v-form ref="form2">
            <v-text-field
              single-line
              v-model="enterId2"
              :rules="id2"
              :disabled="!checkPerUser || !isEffective"
              @blur="checkId2"
              @keydown.enter.prevent="checkId2"
            ></v-text-field>
            <v-btn
              class="t-btn--red-dark mt-3"
              @click="changeid2"
              :disabled="!checkPerUser || !isAssetClient2 || !enterId2.length"
            >
              相続
            </v-btn>
          </v-form>
        </div>
      </template>

      <!-- End Content  -->
    </contract-detail>
    <transfer-contract
      :visible="visible === 1"
      :enterId="enterId"
      :textName="textName"
      @close="visible = -1"
      @done="$reloadRoute"
    ></transfer-contract>
    <transfer-contract-2
      :visible="visible === 2"
      :enterId2="enterId2"
      :textName="textName"
      @close="visible = -1"
      @done="$reloadRoute"
    ></transfer-contract-2>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import contractDetail from '../../ContractDetail.vue';
import TransferContract from './TransferContract.vue';
import TransferContract2 from './TransferContract2.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
// import facilitySettingListVue from '../../../../dashboard/webPageManage/facilitySetting/facilitySettingList.vue';

export default {
  name: 'MWcontract',
  props: ['textID'],
  data() {
    return {
      isAssetClient: null,
      enteredID: false,
      isAssetClient2: null,
      enteredID2: false,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      enterId: '',
      enterId2: '',
      visible: -1,
      dialog: -1,
      id1: [(v) =>  (!!v && (this.isAssetClient??true)===true && this.enterId.length !== 0) || 'IDが存在しません'],
      id2: [(v) => (!!v && (this.isAssetClient2??true)===true && this.enterId2.length !== 0) || 'IDが存在しません'],
      textName: ''
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(['getClientBasicInfoTra']),
  },
  watch: {
    enterId() {
      this.isAssetClient = null
    },
    enterId2() {
      this.isAssetClient2 = null
    }
  },
  methods: {
    ...mapActions(['clientBasicInfoList']),
    ...mapMutations(['setLoadingOverlayShow', 'setLoadingOverlayHide']),
    checkId () {
      this.isAssetClient = false
      this.$refs.form1.resetValidation()
      this.setLoadingOverlayShow()
      setTimeout(() => {
        this.checkClient(this.enterId, 1)
        this.clientBasicInfoList(parseInt(this.enterId)).then((result) => {
          this.textName = result.name
        })
      }, 500)
    },
    checkId2 () {
      this.isAssetClient2 = false
      this.$refs.form2.resetValidation()
      this.setLoadingOverlayShow()
      setTimeout(() => {
        this.checkClient(this.enterId2, 2)
        this.clientBasicInfoList(parseInt(this.enterId2)).then((result) => {
          this.textName = result.name
        })
      }, 500)
    },
    show () {
      this.showLink = 1;
    },
    async checkClient(e, type) {
      // type === 1 : 譲渡先顧客番号
      // type === 2 : 相続先顧客番号
      await this.$apollo        
      .query({
        query: gql`
          query($id: Int!){
            clientBasicInfo(
              id: $id
            ){
              id
              name
            }
          }
        `,
        variables: {
          id: parseInt(e)
        },
        fetchPolicy: 'no-cache'
      }).then(data => {
        if(type === 1) {
          this.isAssetClient = true
          this.$refs.form1.resetValidation()
        }else {
          this.isAssetClient2 = true
          this.$refs.form2.resetValidation()
        }
        this.setLoadingOverlayHide()
      }).catch(err => {
        console.log(err)
        if(type === 1) {
          this.isAssetClient = false
          this.$refs.form1.validate()
        }else {
          this.isAssetClient2 = false
          this.$refs.form2.validate()
        }
        this.setLoadingOverlayHide()
      })
    },
    changeid() {
      if (this.$refs.form1.validate()) {
        this.visible = 1;
        this.$refs.form1.resetValidation();
        // this.$refs.form1.reset();
      }
    },
    changeid2() {
      if (this.$refs.form2.validate()) {
        this.visible = 2;
        this.$refs.form2.resetValidation();
        // this.$refs.form1.reset();
      }
    },
  },
  components: {
    contractDetail,
    TransferContract,
    TransferContract2,
  },
};
</script>

<style lang="scss" scoped>
.title-deposit-date {
  font-size: 8px;
  margin-bottom: 5px;
}
.btn-crm-primary {
  color: #1873d9 !important;
  font-weight: 600;
}
.v-btn {
  height: 32px;
  width: 132px !important;
}
.w-10 {
  width: 10%;
}
h6 {
  font-size: 10px;
  color: #000 !important;
  font-weight: 600;
}
.v-divider {
  border: 1px solid #888888 !important;
}
</style>
